/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled, { keyframes } from "styled-components";
// import { v4 as uuidv4 } from "uuid";
import { useEtc } from "../context/EtcContext";
import DrinksContent from "../components/DrinksContent";
import FoodContent from "../components/FoodContent";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ResponsiveImage from "../components/Img/ResponsiveImage";
// import Brunch from "../components/Brunch";

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
const navbarLinks = [
  {
    name: "dinner",
    name_fr: "dîner",
  },
  {
    name: "drinks",
    name_fr: "boissons",
  },
  // {
  //   name: "brunch",
  //   name_fr: "brunch",
  // },
];
const MenuPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isActive, setIsActive] = useState(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const {
    state: { menu },
    dispatch,
  } = useEtc();
  // const handleMenuItem = (value) => {
  //   dispatch({ type: "menu-item", payload: value });
  // };
  const handleMenuHeader = useCallback(
    (value) => {
      dispatch({ type: "menu", payload: value });
      setIsActive(false);
    },
    [dispatch]
  );
  useLayoutEffect(() => {
    // Update the navbar state when the hash changes
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash === "dinner") {
        handleMenuHeader("dinner");
      } else if (hash === "drinks") {
        handleMenuHeader("drinks");
      }
      // } else if (hash === "weekend-brunch") {
      //   handleMenuHeader("brunch");
      // }
    };

    window.addEventListener("hashchange", handleHashChange);

    // Call handleHashChange initially to set the initial state
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleMenuHeader, i18n.language]);
  // let foundIndex = menuData.findIndex((el) => el.id === menuItemId);
  const dropdownSection = useRef();
  const menuContainer = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownSection.current &&
        !dropdownSection.current.contains(event.target) &&
        !menuContainer.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);
  return (
    <MenuDiv>
      <Menu>
        <aside>
          <div>
            <ResponsiveImage src={"ETC_ele_light_02.webp"} alt="element-2" />
            <ResponsiveImage src={"ETC_ele_light_01.webp"} alt="element-1" />
          </div>
          <div>
            <ResponsiveImage src={"ETC_ele_light_02.webp"} alt="element-2" />
            <ResponsiveImage src={"ETC_ele_light_01.webp"} alt="element-1" />
          </div>
          <Main>
            <ul>
              <li
                onClick={() => handleMenuHeader("dinner")}
                className={menu === "dinner" ? "active-nav" : undefined}
              >
                <h1
                  className={
                    menu === "dinner" ? "active-nav-header" : undefined
                  }
                >
                  <span
                    className={
                      menu === "dinner" ? "active-nav-span" : undefined
                    }
                  >
                    {i18n.language === "en" ? "Dinner" : "Dîner"}
                  </span>
                </h1>
              </li>
              <li
                onClick={() => handleMenuHeader("drinks")}
                className={menu === "drinks" ? "active-nav" : undefined}
              >
                <h1
                  className={
                    menu === "drinks" ? "active-nav-header" : undefined
                  }
                >
                  <span
                    className={
                      menu === "drinks" ? "active-nav-span" : undefined
                    }
                  >
                    {i18n.language === "en" ? "Drinks" : "Boissons"}
                  </span>
                </h1>
              </li>
              {/* <li
                onClick={() => handleMenuHeader("brunch")}
                className={menu === "brunch" ? "active-nav" : undefined}
              >
                <h1
                  className={
                    menu === "brunch" ? "active-nav-header" : undefined
                  }
                >
                  <span
                    className={
                      menu === "brunch" ? "active-nav-span" : undefined
                    }
                  >
                    Brunch
                  </span>
                </h1>
              </li> */}
            </ul>
            <SpecialsContainer>
              <p>
                {i18n.language === "en"
                  ? "Early Bird Special"
                  : "Spécial lève-tôt"}
              </p>
              <a
                href={`/pdf/Early-Bird-Offer-Print-Menu-4.25x8-${
                  i18n.language === "en" ? "EN" : "FR"
                }.pdf`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {i18n.language === "en" ? "View the menu" : "Voir le menu"}
              </a>
            </SpecialsContainer>
            {/* <DropDownMenu
              ref={menuContainer}
              onClick={() => setIsActive(!isActive)}
            >
              <ul>
                <li>{menu}</li>
              </ul>
              {isActive && (
                <ol ref={dropdownSection}>
                  {navbarLinks.map((el, ind) => (
                    <li onClick={() => handleMenuHeader(el.name)} key={ind}>
                      {i18n.language === "en" ? el.name : el.name_fr}
                    </li>
                  ))}
                </ol>
              )}
              <img src={"/img/icons/chevron-down.svg"} alt="chevron-down" />
            </DropDownMenu> */}
            {menu === "dinner" || menu === "dîner" ? <FoodContent /> : null}
            {menu === "drinks" || menu === "boissons" ? (
              <DrinksContent />
            ) : null}
            {/* {menu === "brunch" || menu === "brunch" ? <Brunch /> : null} */}
          </Main>
        </aside>
      </Menu>
    </MenuDiv>
  );
};
const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotateLeft = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MenuDiv = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  transition: 0.4s ease-in-out;
  background: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    display: block;
    overflow-y: scroll;
    /* padding: 100px 20px 40px 20px; */
  }
`;
const Menu = styled.div`
  height: 100%;
  /* max-height: 700px; */
  width: 100%;
  /* max-width: 1100px; */
  display: flex;
  /* padding: 80px 40px; */
  & > aside {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: var(--primary);
    & > div:first-child {
      position: relative;
      width: 100%;
      max-width: 400px;
      height: auto;
      left: -160px;
      top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img:first-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 400px;
        animation: ${rotateRight} 70s linear infinite;
        z-index: 1;
      }
      & > img:last-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 200px;
        animation: ${rotateLeft} 70s linear infinite;
        z-index: 2;
      }
    }
    & > div:nth-child(2) {
      position: absolute;
      width: 100%;
      max-width: 400px;
      height: auto;
      right: -160px;
      bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img:first-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 400px;
        animation: ${rotateRight} 70s linear infinite;
        z-index: 1;
      }
      & > img:last-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 200px;
        animation: ${rotateLeft} 70s linear infinite;
        z-index: 2;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    flex-flow: column nowrap;
    padding: 0;
    height: auto;
    & > aside {
      flex-flow: column-reverse wrap;
      max-height: unset;
      & > div:first-child {
        top: unset;
        position: absolute;
        bottom: 30px;
        & > img:first-child {
          max-width: 280px;
        }
        & > img:last-child {
          max-width: 140px;
        }
      }
      & > div:nth-child(2) {
        bottom: unset;
        top: 110px;
        & > img:first-child {
          max-width: 280px;
        }
        & > img:last-child {
          max-width: 130px;
        }
      }
    }
  }
`;
const Main = styled.main`
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--white);
  z-index: 3;
  padding: 120px 40px 40px 40px;
  & > ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: space-evenly;
    position: relative;
    /* height: 30px; */
    width: 100%;
    max-width: 1120px;
    /* max-width: 640px; */
    margin: 0 auto;
    padding: 0 20px;
    flex: 1fr 1fr;
    /* .active-nav {
      scale: 1.4;
    } */
    border-bottom: 2px solid var(--secondary);
    & > li {
      position: relative;
      transition: all 0.1s linear;
      cursor: pointer;
      text-align: center;
      & > h1 {
        font-weight: lighter;
        font-size: 36px;
        width: 100%;
        & > span {
          width: 100%;
          max-width: 90px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: var(--white);
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
          &.active-nav-span {
            border-bottom: 1px solid var(--white);
            &:hover::after {
              transform: scaleX(0);
              transform-origin: bottom left;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    display: flex;
    flex-direction: column;
    padding: 120px 20px 20px 20px;
    & > ul {
      padding: 0;
      margin-bottom: 20px;
      & > li {
        & > h1 {
          font-size: 28px;
          /* &.active-nav-header {
            & > span {
              font-size: 16px;
            }
          } */
        }
      }
    }
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  width: calc(100% - 50px);
  border-radius: 30px;
  border: 1px solid var(--white);
  height: 66px;
  display: flex;
  cursor: pointer;
  transition: 0.4s all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  z-index: 11;
  & > img {
    position: absolute;
    right: 20px;
    width: 40px;
    height: 40px;
    z-index: 12;
  }
  & > ul {
    margin: 0;
    padding-left: 20px;
    padding: 4px 0;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    & > li {
      width: 100%;
      z-index: 2;
      cursor: pointer;
      padding: 0;
      transition: 0.1s all;
      padding: 10px;
      font-size: 36px;
    }
  }
  & > ol {
    height: auto;
    max-height: 500px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    border-radius: 30px;
    top: 70px;
    left: 0%;
    outline: none;
    border: none;
    list-style: none;
    padding: 0;
    padding: 4px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--primary);
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: var(--scroll_track);
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--scroll_thumb);
      border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--scroll_thumb_hover);
    }
    & > li {
      width: 100%;
      z-index: 1;
      cursor: pointer;
      padding: 0;
      transition: 0.1s all;
      padding: 10px;
      font-size: 36px;
      & > a {
        padding: 10px;
        width: 100%;
      }
      &:hover {
        background: var(--red);
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    height: 54px;
    & > ol,
    & > ul {
      ::-webkit-scrollbar {
        width: 2px;
      }
      & > li {
        padding: 8px;
        font-size: 28px;
      }
    }
  }
`;
const SpecialsContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 20px auto 0 auto;
  background-color: var(--grey);
  background-image: url(/img/bg_pattern.webp);
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  & > a {
    border: none;
    padding: 10px 25px;
    font-size: 18px;
    color: var(--white);
    border: 1px solid var(--white);
    background: none;
    outline: none;
    border-radius: 20px;
    transition: opacity 0.4s ease-in;
    &:hover {
      transition: all 0.3s ease-in;
      background: var(--white);
      color: var(--black);
    }
  }
  & > p {
    font-size: 36px;
  }
  @media screen and (max-width: 991px) {
    gap: 20px;
    & > p {
      font-size: 28px;
    }
  }
`;
export default MenuPage;
